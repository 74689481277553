import { setToken, removeToken, setLopToken, removeLopToken, setUserPhone } from '@/utils/auth';
import * as mutationTypes from 'constants/mutation-types';
import { login, getUserInfo, getPhoneCode, phoneLogin } from '@/api';

export default {

  startPhoneCodeCountDown({ commit }, count) {
    return new Promise((resolve) => {
      commit(mutationTypes.SET_PHONE_CODE_COUNT_DOWN, count);
      resolve();
    });
  },

  getPhoneCode({ commit }, mobile) {
    return getPhoneCode(mobile)
      .then(() => {
        // 验证码发送倒计时，避免用户频繁触发该请求
        commit(mutationTypes.SET_PHONE_CODE_COUNT_DOWN, 60);
      });
  },

  phoneLogin({ commit }, loginInfo) {
    const { phone, code } = loginInfo;
    // lop服务器登录
    return phoneLogin(phone, code)
      .then((res) => {
        // lop服务器登录结果处理
        const { token } = res.data;
        commit(mutationTypes.SET_LOP_TOKEN, token);
        setLopToken(token);
        setUserPhone(token, phone);
      });
  },

  login({ commit }, userInfo) {
    const { userId, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ userId, password })
        .then((res) => {
          const { token } = res;
          commit(mutationTypes.SET_TOKEN, token);
          setToken(token);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit(mutationTypes.SET_TOKEN, '');
      commit(mutationTypes.SET_LOP_TOKEN, '');
      removeToken();
      removeLopToken();
      resolve();
    });
  },

  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserInfo(state.token)
        .then((res) => {
          const { data } = res;

          if (!data) {
            reject('用户信息认证失败，请重新登录');
          }

          const { userId, userName, userAvatar, userSig } = data;

          commit(mutationTypes.UPDATE_USER_INFO, {
            userId,
            userName,
            userAvatar,
          });
          commit(mutationTypes.SET_USER_SIG, userSig);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout({ dispatch }) {
    return new Promise((resolve) => {
      dispatch('resetToken').then(() => {
        location.reload();
      });
      resolve();
    });
  },
};
