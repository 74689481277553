/*
 * @Description: 这里是 TUIPlayer 应用的基础信息配置
 * @Date: 2021-10-19 16:53:28
 * @LastEditTime: 2022-02-17 17:39:52
 */

/**
 * Tencent Cloud SDKAppId, which should be replaced with user's SDKAppId.
 * Enter Tencent Cloud TRTC [Console] (https://console.cloud.tencent.com/trtc ) to create an application,
 * and you will see the SDKAppId.
 * It is a unique identifier used by Tencent Cloud to identify users.
 *
 * 腾讯云 SDKAppId，需要替换为您自己账号下的 SDKAppId。
 * 进入腾讯云实时音视频[控制台](https://console.cloud.tencent.com/rav ) 创建应用，即可看到 SDKAppId，
 * 它是腾讯云用于区分客户的唯一标识。
 */
export const sdkAppId = 1400685951;

/**
 * Set the room information,
 * please ensure that TUIPusher & TUIPlayer room information is the same
 *
 * 设置房间信息，请保证TUIPusher&TUIPlayer房间信息一致
 */
export const roomInfo = {
  // 房间id, TUIPusher和TUIPlayer的roomId应保持一致
  roomId: 100123456,
  // 房间昵称
  roomName: '主日直播',
};

/**
 * Set the user information on the push side,
 * please ensure that TUIPusher & TUIPlayer anchor information is the same
 * Note: The web side screen sharing stream and audio/video stream are two Clients,
 * the screen sharing stream user id is `share_${userId}`
 *
 * 设置推流端用户信息, 请保证TUIPusher&TUIPlayer主播信息一致
 * 注意：web端屏幕分享流和音视频流为两个Client, 屏幕分享流用户id为`share_${userId}`
 */
export const anchorUserInfo = {
  // 用户ID
  userId: '13675892929',
  // 用户昵称
  userName: '主播',
  // 用户头像
  userAvatar: '',
};

/**
 * Set Playback Domain
 *
 * 设置播放域名
 */
export const playerDomain = 'zhibo.landofpromise.co';
