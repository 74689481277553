import Vue from 'vue';
import VueRouter from 'vue-router';
import { getLopToken, getUserPhone } from '@/utils/auth';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/player.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
  const token = getLopToken();

  if (whiteList.indexOf(to.path) !== -1) return next();

  if (token) {
    // TODO:服务器迁移完成后，直接next()跳转即可。目前此步骤是登录季哥服务器。
    const phone = getUserPhone(token);
    store.dispatch('login', { userId: phone, password: '123' }).then(() => {
      next();
    })
      .catch((err) => {
        console.error(err);
        next('/login');
      });
  } else {
    next('/login');
  }
});

export default router;
