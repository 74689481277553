import axios from 'axios/index';
import store from '@/store';
import { Message } from 'element-ui';
import { getLopToken } from '@/utils/auth';

const lopService = axios.create({
  baseURL: 'https://www.landofpromise.co:8081',
  timeout: 300000,
});

lopService.interceptors.request.use(
  (config) => {
    if (store.getters.lopToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.token = getLopToken();
    }
    return config;
  },
  (error) => {
    console.log(`err${error}`);
    return Promise.reject(error);
  },
);

lopService.interceptors.response.use(
  // TODO: 错误拦截，错误信息处理
  (response) => {
    const res = response.data;

    if (res.code !== 200) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 3 * 1000,
      });

      if (res.code === 402) {
        Message({
          message: '登录已失效，请重新登录',
          type: 'error',
          duration: 3 * 1000,
        });

        store.dispatch('resetToken').then(() => {
          location.reload();
        });
      }

      return Promise.reject(new Error(res.msg || 'Error'));
    }

    return res;
  },
  (error) => {
    console.log(`err${error}`);
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000,
    });
  },
);

export default lopService;
