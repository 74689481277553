/*
 * @Description: vuex-state
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-15 18:04:25
 */
import { LIVE_STAGE, LINE_TYPE, LAYOUT } from 'constants/room';
import { getToken, getLopToken } from '@/utils/auth';
import {
  roomInfo,
} from '@/config/basic-info-config';

export default {
  // sdkAppId
  sdkAppId: '',
  // userSig
  userSig: '',
  // 播放域名
  playerDomain: 'zhibo.landofpromise.co',
  // 用户信息
  userInfo: {
    userId: '',
    userName: '',
    userAvatar: '',
  },
  // 主播用户id
  anchorUserId: '',
  // 房间号信息
  roomId: roomInfo.roomId,
  // 阿里云聊天群号信息
  groupId: null,
  // 直播间昵称
  roomName: '',
  // 直播间简介
  roomIntroduce: '',
  // 是否支持 webRTC
  isSupportWebRTC: true,
  // 直播间阶段
  liveStage: LIVE_STAGE.NOT_STARTED,
  // 线路选择-rtc|cdn|leb
  lineType: LINE_TYPE.CDN,
  // 播放状态-playing|paused
  playState: 'paused',
  // horizontal｜vertical
  layout: LAYOUT.VERTICAL,
  // 用户token
  token: getToken(),
  // lop服务器token
  lopToken: getLopToken(),
  // 倒计时
  phoneCodeCountDown: 0,
};
