const TokenKey = 'accessToken';
const LopTokenKey = 'lopAccessToken';

// 季哥服务器登录态维护
export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  localStorage.removeItem(TokenKey);
}

// lop服务器登录态维护
export function getLopToken() {
  return localStorage.getItem(LopTokenKey);
}

export function setLopToken(token) {
  localStorage.setItem(LopTokenKey, token);
}

export function removeLopToken() {
  localStorage.removeItem(LopTokenKey);
}

/**
 * 根据lop服务器登录态token存取手机号，用作季哥服务器登录。
 * 每次进入直播间都需要季哥服务器重新登录。
 * 之所以这样做是因为只有重新登录才能获取最新的用户信息。
 */
export function setUserPhone(token, mobile) {
  localStorage.setItem(token, mobile);
}

export function getUserPhone(token) {
  return localStorage.getItem(token);
}
