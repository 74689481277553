import request from '@/api/request';
import lopRequest from '@/api/lopRequest';

export function getPhoneCode(mobile) {
  return lopRequest({
    url: '/lop_project/api/live/login/get_code',
    method: 'post',
    data: {
      mobile,
    },
  });
}

export function phoneLogin(phone, code) {
  return lopRequest({
    url: '/lop_project/api/live/login',
    method: 'post',
    data: {
      code,
      mobile: phone,
    },
  });
}

export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data,
  });
}

export function getUserInfo(token) {
  return request({
    url: '/api/user/info',
    method: 'get',
    params: {
      token,
    },
  });
}

export function getRoomInfo() {
  return request({
    url: '/api/room/info',
    method: 'get',
  });
}

export function updateUserName(userName) {
  return lopRequest({
    url: '/lop_project/api/live/user/update_username',
    method: 'get',
    params: {
      userName,
    },
  });
}
